export const styleTypes = ["minimalist", "professional", "technical"];
export const themes = {
    minimalist: {
      backgroundColor: '#fbf8f2',
      color: '#505564',
    },
    professional: {
      backgroundColor: 'purple',
      color: 'gold',
    },
    technical: {
        backgroundColor: 'pink',
        color: 'navy',
    },
    // ... more themes
  };